var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VGridRow',[_c('VGridCol',{staticClass:"prescriptions-table__wrapper"},[_c('VTable',{staticClass:"prescriptions-table"},[_c('PrescriptionTableHeader'),_c('VTableBody',[_vm._l((_vm.violations),function(violation,indexViolation){return _c('VTableRow',{key:indexViolation,ref:"refFullTableRows",refInFor:true,on:{"click":_vm.onOpenSecondaryPage}},[_c('RouterLink',{staticClass:"prescriptions-table__route",attrs:{"to":_vm.route(violation.id, violation.object)}},[_c('VGridCol',[_vm._v(" "+_vm._s(violation.id)+" ")]),_c('VGridCol',[_vm._v(" "+_vm._s(violation.project.data.project)+" ")]),_c('VGridCol',{staticClass:"prescriptions-table__status"},[_c('span',{class:{
                                    'prescriptions-table__status--red': [
                                        _vm.StatusEnum.InWork,
                                        _vm.StatusEnum.Cancelled,
                                        _vm.StatusEnum.Rejected,
                                    ].includes(violation.statusId),
                                    'prescriptions-table__status--yellow': [
                                        _vm.StatusEnum.Done,
                                        _vm.StatusEnum.Approval,
                                    ].includes(violation.statusId),
                                    'prescriptions-table__status--green': violation.statusId === _vm.StatusEnum.Accept,
                                    'prescriptions-table__status--purple':
                                        violation.statusId === _vm.StatusEnum.Created,
                                }},[_vm._v(" "+_vm._s(_vm.findStatus(violation.statusId))+" ")])]),_c('VGridCol',{staticClass:"prescriptions-table__hide"},[(violation.drawingTypeId !== null && violation.drawingTypeId !== undefined)?[_vm._v(" "+_vm._s(_vm.setDrawingType(violation.drawingTypeId))+" ")]:[_vm._v("-")]],2),_c('VGridCol',{staticClass:"prescriptions-table__hide-mobile"},[_vm._v(" "+_vm._s(_vm.dateFormat(violation.createdAt, 'dd.mm.yyyy'))+" - "+_vm._s(_vm.dateFormat(violation.deadline, 'dd.mm.yyyy'))+" ")]),_c('VGridCol',{staticClass:"grid-col_center"},[(violation.tasksCount !== null && violation.tasksCount !== undefined)?[_vm._v(" "+_vm._s(violation.tasksCount)+" ")]:[_vm._v("-")]],2),_c('VGridCol',{staticClass:"prescriptions-table__type"},[(violation.object === _vm.prescriptionTypeEnum.Building)?[_c('PrescriptionBuildingSVG'),_vm._v(" Строительство ")]:_vm._e(),(violation.object === _vm.prescriptionTypeEnum.Acceptance)?[_c('PrescriptionAcceptanceSVG'),_vm._v(" Приемка ")]:_vm._e(),(violation.object === _vm.prescriptionTypeEnum.Warranty)?[_c('PrescriptionWarrantySVG'),_vm._v(" Гарантия ")]:_vm._e()],2)],1)],1)}),(!_vm.isRequestReady)?_c('VTableRow',{staticClass:"full-table__spinner grid-row_center"},[_c('VSpinner')],1):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }